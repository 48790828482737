import { Button, Container, Typography } from "@mui/material";
import classNames from "classnames";
import AsyncContent from "@RHCommerceDev/component-async-content";
import HTMLHeadTags from "@RHCommerceDev/component-html-head-tags";
import ProductGrid from "@RHCommerceDev/component-product-grid";
import GridController from "@RHCommerceDev/component-grid-controller";
import RefineMenuDropdown from "@RHCommerceDev/component-refine-menu-dropdown";
import RHLink from "@RHCommerceDev/component-rh-link";
import { LoadingContext } from "graphql-client/contexts/LoadingContext";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import useButtonStyles from "@RHCommerceDev/hooks/useButtonStyles";
import { processEnvServer as isServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import React, { FC } from "react";
import RHRProductListSkeleton from "@RHCommerceDev/skeleton-rhr-product-list";
import { SKELETON_ANIMATION } from "@RHCommerceDev/utils/constants";
import memoize from "@RHCommerceDev/utils/memoize";

import yn from "yn";
import { RHRProductGalleryProps } from "./types";
import { useStyles } from "./NextgenProductGallery.useStyles";
import { useNextgenProductGalleryData } from "./useNextgenProductGalleryData";
import { useGetSchema } from "@RHCommerceDev/hooks/useGetSchema";
import { getClientOrigin } from "@RHCommerceDev/utils/getClientOrigin";
import { usePGProductItemList } from "@RHCommerceDev/hooks/usePGProductItemList";
import RHScrollUpButton from "@RHCommerceDev/component-rh-scroll-up-button";
import useIsoRedirect from "@RHCommerceDev/hooks/useIsoRedirect";
import { SEARCH_RESULTS } from "@RHCommerceDev/resources/page-level-resources-schemas/products.json";

const NextgenProductGalleryV2: FC<RHRProductGalleryProps> = (props => {
  const {
    seoPageTitle,
    productTitle,
    productList,
    prefix,
    params,
    isLoading,
    env,
    isPGPage,
    search,
    tapInfoOptions,
    pageContent,
    navigateToOtherResult,
    smDown,
    contactUsPath,
    isAemEnabled,
    reloadKey,
    shouldShowSaleText,
    standardMemberSavingsMessage,
    activeSaleFilter,
    selectItemsOnSale,
    allItemsOnSale,
    selectSaleItemsOnSale,
    dynamicMemberSavingsText,
    fusionPromoDisplay,
    isSale,
    handleTabClick,
    maxTabId,
    isSSRToggledWithCSR,
    refMenu,
    sortMenu,
    sortingLabel,
    setSortingLabel,
    country,
    totalNumRecs,
    resetRefinement,
    sizeFacets,
    materialFacets,
    selectedRefinementContainer,
    categoryId,
    booleanFacets,
    currectActiveTab,
    gridColumns,
    saveGridCol,
    mdUp,
    facetLength,
    isLazyLoadEnabled,
    isNextPageLoading,
    isSaleFilterEnabled,
    loadMoreData,
    isStockedFilterActive,
    isRefinementFilterActive,
    templateString,
    brand,
    isSort,
    cookie,
    saveSelectedProduct,
    isConcierge,
    aemAsyncFooterModel,
    seoPageDescription,
    totalSearchResultCount,
    isNewConceptTabs,
    sortedConceptTabs,
    isStandardMemberSavingsActive,
    error
  } = useNextgenProductGalleryData(props);

  const rhUser = useRhUserAtomValue();
  const nextgenCookie = "true";
  const hostName = isServer ? getClientOrigin() : window?.location?.origin;
  const isSkuSearch = search?.skuSearch && !!search?.resultList?.totalNumRecs;
  const searchResultsText = pageContent?.SEARCH_RESULTS ?? SEARCH_RESULTS;

  const typographyClasses = useTypographyStyles({
    keys: [
      "saleTitle",
      "saleSubTitle",
      "searchInput",
      "searchAutoSuggestResult",
      "rhrSearchTitle",
      "rhrSearchAutoSuggestResult",
      "rhBaseH4",
      "rhBaseH1",
      "rhBaseH2",
      "rhBaseCaption",
      "rhBody1"
    ]
  });

  const classes = useStyles();

  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn"]
  });

  const { productItemList } = usePGProductItemList({
    hostName,
    prefix,
    isStockedFilterActive,
    isRefinementFilterActive,
    totalNumRecs,
    isSale,
    isSaleFilterEnabled,
    isConcierge,
    search,
    nextgenCookie,
    productList,
    rhUser
  });
  const limitedProductItemList = productItemList?.slice(0, 10);

  const itemListSchema = useGetSchema("ItemList", {
    name: seoPageTitle || productTitle,
    description: seoPageDescription,
    itemList: limitedProductItemList
  });

  if (!!error) {
    useIsoRedirect("/error/search-not-available.jsp", "redirect");
    return null;
  }

  return (
    <>
      <HTMLHeadTags
        title={productTitle ? productTitle : seoPageTitle}
        description={seoPageDescription}
        // description={
        //   params.fromCG === "true"
        //     ? root?.seoDescription || root?.bannerCopy
        //     : root?.seoDescription
        // }
        // keywords={keywords}
        image={productList?.length > 0 ? productList[0]?.product?.imageUrl : ""}
        schema={itemListSchema}
      />
      <div style={{ display: "none" }}>SSR V1</div>
      {/*AsyncContent loads synchronously when not being loaded as an AEM component */}
      {isAemEnabled ? (
        <AsyncContent
          path={`${prefix}/category/${params.categoryId || categoryId}/header`}
          isInEditor={false}
          model={pageContent?.pgHeader}
        />
      ) : null}
      <LoadingContext.Provider value={{ isLoading }}>
        {!isLoading &&
        (!yn(env.FEATURE_SEARCH_ALL_BRANDS) || isPGPage
          ? search?.zeroResults ||
            (search?.skuSearch && !search?.resultList?.totalNumRecs)
          : search?.zeroResults && !tapInfoOptions.length) ? (
          <>
            {(search?.zeroResults?.otherBrandSearchResults || []).length > 0 ? (
              <div className="!bg-white-400">
                {productTitle ? (
                  <Container>
                    <h1
                      className="text-4xl font-primary-ultra-thin uppercase"
                      dangerouslySetInnerHTML={{
                        __html: productTitle
                      }}
                    />
                  </Container>
                ) : null}

                <Container className="pb-10 xs:pb-6 sm:pb-9 md:pb-12 lg:pb-[60px] xl:pb-20">
                  <Typography
                    className={classNames([
                      typographyClasses.rhBaseCaption,
                      "flex-none !mr-[30px] lg:!mr-10 xl:!mr-[60px]"
                    ])}
                  >
                    {pageContent?.SEARCH_RESULTS}{" "}
                    {`( ${
                      (search?.zeroResults?.otherBrandSearchResults || [])[0]
                        ?.brandResultCount
                    } IN ${
                      (search?.zeroResults?.otherBrandSearchResults || [])[0]
                        ?.brandName
                    } )`}
                  </Typography>
                </Container>

                <Container className="!pt-6">
                  <Button
                    className={buttonStyles.primaryBlackBtn}
                    data-testid="add-to-cart-dialog-opener"
                    onClick={navigateToOtherResult}
                  >
                    {`VIEW ${
                      (search?.zeroResults?.otherBrandSearchResults || [])[0]
                        ?.brandName
                    } ${searchResultsText}`}
                  </Button>
                </Container>
              </div>
            ) : (
              <div className="!bg-white-400">
                <Container>
                  <Typography
                    variant="h1"
                    className={classNames([
                      classes.actionTitle,
                      typographyClasses.rhBaseH1,
                      "inline !uppercase mr-5 text-center",
                      {
                        "text-center": smDown,
                        "m-0": smDown,
                        block: smDown
                      }
                    ])}
                    dangerouslySetInnerHTML={{
                      __html: productTitle
                    }}
                  />
                </Container>
                <Container className="pb-10 xs:pb-6 sm:pb-9 md:pb-12 lg:pb-[60px] xl:pb-20">
                  <Typography
                    className={classNames([
                      typographyClasses.rhBaseCaption,
                      "flex-none mr-[30px] lg:mr-10 xl:mr-[60px]"
                    ])}
                  >
                    {pageContent?.SEARCH_RESULTS} (0)
                  </Typography>
                </Container>
                <Container className="!pt-6">
                  <Typography className={typographyClasses.rhBaseCaption}>
                    {
                      pageContent?.WERE_SORRY_WE_CANNOT_FIND_WHAT_YOU_ARE_LOOKING_FOR
                    }
                  </Typography>
                  <Typography className={typographyClasses.rhBaseCaption}>
                    {pageContent?.PLEASE_TRY_A_NEW_SEARCH_OR_CONTACT}

                    <RHLink
                      to={contactUsPath}
                      data-testid={"customer-service-link"}
                      style={{ display: "initial" }}
                    >
                      <Typography
                        className={classNames([
                          typographyClasses.rhrSearchAutoSuggestResult,
                          typographyClasses.rhBaseCaption,
                          "!capitalize underline hover:border-b-0"
                        ])}
                      >
                        {pageContent?.CUSTOMER_EXPERIENCE}
                      </Typography>
                    </RHLink>
                  </Typography>
                </Container>
              </div>
            )}
          </>
        ) : (
          <>
            {/* the bottom section should not render if it is a AEM page */}
            {!isAemEnabled && isSkuSearch === false && (
              <>
                <Container
                  key={(reloadKey ? reloadKey : 123) + "productTitle"}
                  className={classNames(
                    classes.galleryContainer,
                    "xl:mt-[44px] lg:mt-[44px] md:mt-[44px] sm:mt-[52px] mt-[32px]"
                  )}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div
                    className={classNames([classes.actionContainer])}
                    style={
                      isPGPage || !yn(env.FEATURE_SEARCH_ALL_BRANDS)
                        ? { paddingBottom: 8, alignItems: "center" }
                        : {}
                    }
                  >
                    <div
                      className={classNames([
                        isNewConceptTabs
                          ? "sm:justify-normal justify-center gap-1 flex-row"
                          : "",
                        classes.actionTitleWrapper
                      ])}
                    >
                      <div className="flex items-baseline sm:text-left text-center justify-center flex-wrap">
                        {productTitle ? (
                          <Typography
                            variant="h1"
                            className={classNames([
                              classes.actionTitle,
                              typographyClasses.rhBaseH2,
                              isNewConceptTabs
                                ? `text-[32px] leading-8 font-primary-rhthin sm:order-1 order-2 before:content-['“'] after:content-['”']`
                                : ""
                            ])}
                            style={{}}
                            dangerouslySetInnerHTML={{
                              __html: productTitle
                            }}
                          />
                        ) : null}
                        {isNewConceptTabs && !isLoading && (
                          <Typography
                            className={classNames([
                              "relative top-0 flex items-center bg-transparent border-none !cursor-default !text-[13px] !leading-[19px] !tracking-[0.04em] sm:order-2 order-3 mt-1.5 ml-1"
                            ])}
                          >
                            {`(${totalSearchResultCount?.toLocaleString()} ${
                              pageContent?.SEARCH_RESULTS
                            })`}
                          </Typography>
                        )}
                      </div>
                      {isStandardMemberSavingsActive && (
                        <div
                          className={classNames(
                            isNewConceptTabs
                              ? "sm:order-3 order-1 sm:mb-0 mb-6"
                              : "",
                            classes.membershipContainer
                          )}
                        >
                          <Typography className={classes.membershipMessage}>
                            <RHLink
                              to="/membership"
                              underline="always"
                              data-testid={"rh-membership-link-pg"}
                              style={{
                                display: "inline",
                                color: "#000000",
                                fontFamily: "RHSans-Thin !important",
                                fontSize: "13px"
                              }}
                            >
                              <span className="font-primary-rhthin underline">
                                {pageContent?.RH_MEMBERS_PROGRAM}
                              </span>
                            </RHLink>{" "}
                            {pageContent?.SAVE_25_ON_EVERYTHING}
                          </Typography>
                        </div>
                      )}
                    </div>
                    {shouldShowSaleText &&
                    activeSaleFilter &&
                    (selectItemsOnSale || allItemsOnSale) &&
                    productTitle ? (
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "8px"
                        }}
                      >
                        <Typography
                          variant="h1"
                          className={classes.actionSaleBanner}
                          dangerouslySetInnerHTML={{
                            __html: "SALE"
                          }}
                        />
                      </div>
                    ) : null}
                    {productTitle &&
                      shouldShowSaleText &&
                      activeSaleFilter &&
                      !standardMemberSavingsMessage && (
                        <div
                          style={{
                            width: "100%",
                            paddingTop: selectSaleItemsOnSale ? "8px" : "0px"
                          }}
                        >
                          <Typography
                            variant="h1"
                            className={classes.actionSaleMessage}
                            dangerouslySetInnerHTML={{
                              __html: dynamicMemberSavingsText
                            }}
                          />
                        </div>
                      )}
                  </div>
                </Container>

                {fusionPromoDisplay && !isSale && (
                  <Container className="mt-0 mb-5">
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: fusionPromoDisplay?.map(data => data?.MEDIA)
                      }}
                    />
                  </Container>
                )}

                {yn(env.FEATURE_SEARCH_ALL_BRANDS) &&
                  !isPGPage &&
                  tapInfoOptions?.length > 1 &&
                  !yn(env.FEATURE_CONCEPT_TABS) && (
                    <Container
                      data-testId="concept-tabs"
                      className="flex justify-center gap-2.5 cursor-pointer my-[25px] mx-0"
                    >
                      {tapInfoOptions?.map(data => {
                        return (
                          <Button
                            className={classNames([
                              "!py-[17.5px] !px-5 rounded-[50px] text-[11px] font-primary-light leading-[120%] tracking-[0.44px] bg-gray-100 text-black",
                              {
                                "!bg-black !text-white": !params?.activeTab
                                  ? Number(data?.tabNo) === Number(maxTabId)
                                  : Number(data?.tabNo) ===
                                    Number(params?.activeTab)
                              }
                            ])}
                            onClick={() => handleTabClick(data)}
                            disabled={
                              !params?.activeTab
                                ? Number(data?.tabNo) === Number(maxTabId)
                                : Number(data?.tabNo) ===
                                  Number(params?.activeTab)
                            }
                          >
                            {`${data?.displayName} (${data?.numResults})`}
                          </Button>
                        );
                      })}
                    </Container>
                  )}
              </>
            )}

            <Container
              className={classNames({
                "pb-7 sm:pb-9 md:pb-12 lg:pb-13.5 xl:pb-18.75": !(
                  isNewConceptTabs && tapInfoOptions?.length > 1
                ),
                "sm:pb-8": gridColumns === 6 && smDown
              })}
              key={(reloadKey ? reloadKey : 123) + "refinementContainer"}
            >
              <div
                className={`${
                  isNewConceptTabs && tapInfoOptions?.length > 1
                    ? "sm:flex"
                    : "!pt-[15px] flex"
                } justify-center items-center`}
              >
                {isNewConceptTabs && tapInfoOptions?.length > 1 ? (
                  <Container
                    data-testId="new-concept-tabs"
                    className={`flex ${
                      tapInfoOptions?.length === 4
                        ? "gap-[16px]"
                        : "gap-[24px] sm:gap-[16px]"
                    } justify-start cursor-pointer mt-6 px-0 xl:mb-[44px] lg:mb-[24px] md:mb-[24px] sm:mb-[32px] mb-[24px]`}
                  >
                    {sortedConceptTabs?.map(data => {
                      return (
                        <Button
                          className={classNames([
                            "text-[11px] font-primary-light leading-3 tracking-[0.44px] text-black !p-0 !py-1 !min-w-0 border-solid hover:!bg-transparent font-light",
                            {
                              "border-b border-[#000]":
                                Number(data?.tabNo) ===
                                Number(params?.activeTab),
                              "border-b-[0.5px] border-gray-500":
                                Number(data?.tabNo) !==
                                Number(params?.activeTab)
                            }
                          ])}
                          onClick={() => handleTabClick(data)}
                        >
                          {`${
                            data?.displayName
                          } (${data?.numResults?.toLocaleString()})`}
                        </Button>
                      );
                    })}
                  </Container>
                ) : (
                  <RefineMenuDropdown
                    refinementMenus={
                      isSSRToggledWithCSR
                        ? search?.refinementMenus || refMenu
                        : refMenu
                    }
                    sortMenu={
                      isSSRToggledWithCSR
                        ? search?.resultList?.sortOptions || sortMenu
                        : sortMenu
                    }
                    totalCount={totalSearchResultCount}
                    resetRefinement={resetRefinement}
                    resultTabs={search?.resultTabs}
                    showResetRefinement={
                      !!params.n || !!params?.filters?.length
                    }
                    sizeFacets={
                      isSSRToggledWithCSR
                        ? search?.sizeFacets || sizeFacets
                        : sizeFacets
                    }
                    materialFacets={
                      isSSRToggledWithCSR
                        ? search?.materialFacets || materialFacets
                        : materialFacets
                    }
                    selectedRefinementContainer={
                      selectedRefinementContainer.current
                    }
                    categoryId={params.categoryId || categoryId}
                    isSale={isSale}
                    booleanFacet={
                      isSSRToggledWithCSR
                        ? search?.booleanFacets || booleanFacets
                        : booleanFacets
                    }
                    loading={isLoading}
                    version={params?.version}
                    activeTab={currectActiveTab ? currectActiveTab : null}
                    isNewConceptTabs={isNewConceptTabs}
                  />
                )}
                {isNewConceptTabs
                  ? isSkuSearch === false && (
                      <div className="flex justify-end">
                        <GridController
                          gridColumns={gridColumns}
                          setGridColumns={saveGridCol}
                          sortMenu={search?.resultList?.sortOptions || sortMenu}
                          categoryId={params.categoryId || categoryId}
                          hide1Column={mdUp ? true : false}
                          ssr={isSSRToggledWithCSR}
                          facetlength={facetLength}
                          activeTab={currectActiveTab ? currectActiveTab : null}
                          sortingLabel={sortingLabel}
                          setSortingLabel={setSortingLabel}
                          country={country}
                        />
                      </div>
                    )
                  : isSkuSearch === false && (
                      <GridController
                        gridColumns={gridColumns}
                        setGridColumns={saveGridCol}
                        sortMenu={search?.resultList?.sortOptions || sortMenu}
                        categoryId={params.categoryId || categoryId}
                        hide1Column={mdUp ? true : false}
                        ssr={isSSRToggledWithCSR}
                        facetlength={facetLength}
                        activeTab={currectActiveTab ? currectActiveTab : null}
                        sortingLabel={sortingLabel}
                        setSortingLabel={setSortingLabel}
                        country={country}
                      />
                    )}
              </div>
              <div
                ref={selectedRefinementContainer}
                id="refinement-container"
              ></div>
            </Container>
            {isAemEnabled &&
              !pageContent?.pgHeader?.templateName &&
              isSkuSearch === false && (
                <Container>
                  <div className="text-center pb-10">
                    <Typography
                      variant="h1"
                      className={classNames([
                        classes.actionTitle,
                        typographyClasses.rhBaseH2,
                        "inline !uppercase mr-5 text-center",
                        {
                          "text-center": smDown,
                          "m-0": smDown,
                          block: smDown
                        }
                      ])}
                      dangerouslySetInnerHTML={{
                        __html: productTitle
                      }}
                    />
                  </div>
                </Container>
              )}
            <Container>
              {!isServer &&
              (isLazyLoadEnabled
                ? !isNextPageLoading && isLoading
                : isLoading) ? (
                <RHRProductListSkeleton
                  hasBanner={true}
                  columns={gridColumns}
                  numItems={12}
                  disableProductInfoSkeleton
                  animation={SKELETON_ANIMATION}
                />
              ) : (
                isSkuSearch === false && (
                  <>
                    {/* <ImageSizeProvider> */}
                    <ProductGrid
                      isSale={isSale}
                      isSaleFilterEnabled={isSaleFilterEnabled}
                      totalNumRecs={
                        isSSRToggledWithCSR
                          ? search?.resultList?.totalNumRecs || totalNumRecs
                          : totalNumRecs
                      }
                      firstRecNum={search?.resultList?.firstRecNum}
                      lastRecNum={search?.resultList?.lastRecNum}
                      loadMoreData={loadMoreData}
                      recsPerPage={params.maxnrpp}
                      productList={
                        isServer ? search?.resultList?.records : productList
                      }
                      isStockedFilterActive={isStockedFilterActive}
                      isRefinementFilterActive={isRefinementFilterActive}
                      gridColumns={gridColumns}
                      view={params.view || templateString || "vertical"}
                      brand={brand}
                      isSort={isSort}
                      productClickHandler={saveSelectedProduct}
                      disableFadeEffect={isConcierge}
                      productTitle={productTitle}
                      // pgCropRulesFromCg={pgCropRules}
                      infiniteScrollEnabled={isLazyLoadEnabled}
                      isNextPageLoading={isNextPageLoading}
                      filterQueries={search?.filterQueries}
                      inStockFlow={search?.inStockFlow!}
                      activeTab={currectActiveTab ? currectActiveTab : null}
                    />
                    <RHScrollUpButton />
                    {/* </ImageSizeProvider> */}
                  </>
                )
              )}
            </Container>
          </>
        )}
      </LoadingContext.Provider>
      {/*AsyncContent loads synchronously when not being loaded as an AEM component */}
      {isAemEnabled ? (
        <AsyncContent
          path={`${prefix}/category/${params.categoryId || categoryId}/footer`}
          isInEditor={false}
          model={pageContent?.pgFooter}
        />
      ) : null}
    </>
  );
}) as any;

// export default memoize(NextgenProductGallery);

export default memoize(props => {
  return (
    <>
      <NextgenProductGalleryV2 {...props} />{" "}
      <div className="hidden">SSR V1</div>
    </>
  );
});
